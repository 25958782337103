<template>
	<div class="coreteam">
		<img src="../assets/boss/title.png" alt="" class="title" />
		<div class="intro">
			<img src="../assets/boss/boss.png" alt="" class="left" />
			<div class="right">
				<div class="name">Eddie Chong</div>
				<div class="font">
					M3 DAO 早期成员
					<br />
					Ecosystem Lead
				</div>
				<div class="content">
					<!-- BTC早期布道者、比特币矿场主、马来西亚企业家，
					X Infinity 控股有限公司董事总经理、X-PAY创始人。
					拥有超过400家实体企业、多家上市公司总裁、全球社区领导者。 -->
					BTC早期布道者、比特币矿场主、马来西亚企业家，
					<br />
					X Infinity 创始人 
					<br />
					X-Pay 创始人 
					<br />
					MICT 创始人 
					<br />
					Delta Aerospace 股东 
					<br />
					WebTVAsia 股东
					<br />
					V Sing 股东
					<br />
					拥有超过400家实体企业、多家上市公司总裁、全球社区领导者
				</div>
				<div class="ewm">
					<div class="item">
						<img class="top" src="../assets/boss/whats.png" alt="" />
						<a
							target="_blank"
							href="https://api.whatsapp.com/send/?phone=971554036674&text&type=phone_number&app_absent=0"
						>
							<img class="bottom" src="../assets/boss/wahts_icon.png" alt=""
						/></a>
					</div>
					<div class="item">
						<img class="top" src="../assets/boss/tele.png" alt="" />
						<a href="https://t.me/Eddiechong20200" target="_blank">
							<img class="bottom" src="../assets/boss/tele_icon.png" alt="" />
						</a>
					</div>
				</div>
			</div>
		</div>
		<div class="body">
			<div class="body_title">
				<img src="../assets/boss/ry.png" alt="" />
				<span> 荣誉</span>
			</div>
			<div class="body_content">
				<ul>
					<!-- <li>2022年东盟华企卓越表扬奖（金融科技）</li>
					<li>2022年东盟华人杰出表扬奖（金融科技）</li>
					<li>2021年度杰出卓智奖 - 国家消费人行动议会</li> -->
					<li>白手兴家东盟海外华人杰出奖 - 2022 年东盟华企卓越表扬奖（金融科技）</li>
					<li>国家消费人行动议会 (MTPN) - 2021 年度杰出卓智奖</li>
				</ul>
				<ul>
					<!-- <li>2019年创世纪亚洲最具影响力电子商务企业奖 - X-Pay</li>
					<li>2018年Satoshi United最佳区块链钱包 - X-Pay</li>
					<li>2018年创世纪最佳互联网金融大奖 - X Infinity</li> -->
					<li>白手兴家东盟海外华人杰出奖 - 2022 年东盟华人杰出表扬奖（金融科技）</li>
					<li>CBERI 中国品牌经济研究院 - 2020年度逆势生长新锐投资人</li>
				</ul>
			</div>
		</div>
		<div class="body">
			<div class="body_title">
				<img src="../assets/boss/xm.png" alt="" />
				<span> 已投项目</span>
			</div>
			<div class="item">
				<img src="@/assets/boss/1.png" alt="" />
				<img src="@/assets/boss/2.png" alt="" />
				<img src="@/assets/boss/3.png" alt="" />
				<img src="@/assets/boss/4.png" alt="" />
			</div>
			<div class="item">
				<img src="@/assets/boss/5.png" alt="" />
				<img src="@/assets/boss/6.png" alt="" />
				<img src="@/assets/boss/7.png" alt="" />
				<img src="@/assets/boss/8.png" alt="" />
			</div>
		</div>
	</div>
</template>

<style scoped lang="scss">
@media screen and (max-width: 768px) {
}
@media screen and (min-width: 768px) {
	.coreteam {
		width: 100%;
		// overflow: auto;
		// height: calc(100vh - 180px);
		background: #000000 url('../assets/boss/back.png');
		background-size: 100% 2510px;
		color: #ffffff;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-bottom: 90px;
		.title {
			width: 1166px;
			height: 178px;
			margin-top: 236px;
		}
		.intro {
			width: 100%;
			padding: 0 200px 80px 240px;
			margin-top: 166px;
			display: flex;
			justify-content: space-between;
			.left {
				width: 632px;
				height: 796px;
				// margin-right: 56px;
			}
			.right {
				width: 780px;
				.name {
					width: 100%;
					font-weight: 600;
					font-size: 50px;
					color: rgba(253, 170, 51, 1);
					line-height: 62.5px;
				}
				.font {
					width: 100%;
					margin-top: 16px;
					font-weight: 600;
					font-size: 36px;
					color: rgba(255, 255, 255, 1);
					line-height: 45px;
				}
				.content {
					width: 100%;
					margin-top: 24px;
					line-height: 38px;
					font-size: 26px;
				}
				.ewm {
					width: 100%;
					margin-top: 24px;
					.item {
						display: inline-flex;
						flex-direction: column;
						align-items: center;
						margin-right: 140px;
						.top {
							width: 218px;
						}
						.bottom {
							margin-top: 10px;
							width: 46px;
						}
					}
				}
			}
		}
		.body {
			width: 1440px;
			display: flex;
			flex-direction: column;
			.body_title {
				margin-top: 100px;
				width: 422px;
				height: 84px;
				background: url('../assets/boss/title_back.png');
				background-size: 422px 84px;
				padding-left: 23px;
				display: flex;
				align-items: center;
				span {
					display: inline-block;
					margin-left: 15px;
					font-weight: 600;
					font-size: 40px;
					color: rgba(0, 0, 0, 1);
				}
				img {
					width: 55px;
				}
			}

			.body_content {
				margin-top: 46px;
				width: 100%;
				display: flex;
				justify-content: space-between;
				li {
					font-weight: 400;
					font-size: 30px;
					line-height: 60px;
				}
				ul {
					width: 636px;
				}
			}

			.item {
				width: 100%;
				display: flex;
				justify-content: space-between;
				margin-top: 48px;
				img {
					width: 310px;
					height: 90px;
				}
			}
		}
	}
}
</style>
